"use client";
import Script from "next/script";

export default function PageMuna() {
    return (
        <>
            <div className="shadow-lg rounded-lg overflow-hidden p-4">
                <flowise-fullchatbot></flowise-fullchatbot>
            </div>
            <Script
                src="https://cdn.jsdelivr.net/gh/almubarokahmasjid/ChatEmbedFlowise@latest/dist/web.js"
                type="module"
                strategy="lazyOnload"
                onLoad={() => {
                    window.Chatbot.initFull({
                        chatflowid: "ef1d2eaa-84a1-4b33-8766-0570f30c21c3",
                        apiHost: "https://flows.mubarokah.com",
                        theme: {
                            button: {
                                backgroundColor: "#0C0F3C",
                            },
                            chatWindow: {
                                showTitle: true,
                                title: "Muna Assistant",
                                titleAvatarSrc: "/muna/muna-avatar.webp",
                                showAgentMessages: true,
                                welcomeMessage: "Hi there! How can I help?",
                                errorMessage: "Oops.. something went wrong. Contact the administrator at chatbotdev@mubarokah.com",
                                backgroundColor: "#ffffff",
                                fontSize: 16,
                                poweredByTextColor: "#303235",
                                botMessage: {
                                    backgroundColor: "#f7f8ff",
                                    textColor: "#303235",
                                    showAvatar: true,
                                    avatarSrc: "/muna/muna-avatar.webp",
                                },
                                userMessage: {
                                    backgroundColor: "#3B81F6",
                                    textColor: "#ffffff",
                                    showAvatar: true,
                                    avatarSrc: "/muna/me.webp",
                                },
                                textInput: {
                                    placeholder: "Type your question",
                                    backgroundColor: "#ffffff",
                                    textColor: "#303235",
                                    sendButtonColor: "#3B81F6",
                                    maxChars: 150,
                                    maxCharsWarningMessage: "You exceeded the characters limit. Please input less than 150 characters.",
                                    autoFocus: true,
                                    sendMessageSound: true,
                                    receiveMessageSound: true,
                                },
                                feedback: {
                                    color: "#303235",
                                },
                                footer: {
                                    textColor: "#303235",
                                    text: "Powered by",
                                    company: "Mubarokah AI (based on Flowise)",
                                    companyLink: "https://mubarokah.com/",
                                },
                            },
                        },
                    });
                }}
            />
        </>
    );
}
